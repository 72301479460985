<template>
  <v-row>
    <v-col class="ProfessionalProfileStatsTile--Tile ma-3">
      <v-row>
        <v-col cols="12" class="ProfessionalProfileStatsTile--TileTitle pb-0">
          <h4>{{ title }}</h4>
        </v-col>
        <v-col cols="12" class="ProfessionalProfileStatsTile--TileTitle py-0">
          <h5>{{ subTitle }}</h5>
        </v-col>
        <v-col cols="12">
          <h1>{{ value }}</h1>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ProfessionalProfileStatsTile",
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/ProfessionalProfile/professional-profile-stats-tile.less";
</style>