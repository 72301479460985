<template>
  <v-card class="my-2 mx-2" color="#f9faff" elevation="0">
    <v-card-text>
      <v-row no-gutters>
        <v-col class="text-h4" cols="8">
          <v-row
            v-if="!!photo"
            no-gutters
            justify="center"
            align="center"
            class="ProCard--Icon"
          >
            <v-img :src="getImage(photo)" alt="Image company"></v-img>
          </v-row>
          <v-row
            v-else
            no-gutters
            class="ProCard--Icon white--text pa-3 text-uppercase fill-width"
            align="center"
            justify="center"
          >
            {{ firstName[0] + lastName[0] }}
          </v-row>
        </v-col>
        <v-col v-if="!hasNotSubscribed" cols="4">
          <v-row no-gutters justify="end">
            <v-icon size="30">mdi-star</v-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-row no-gutters v-if="occupation">
      <v-col cols="12" class="ml-4 ProCard--Occupation">
        {{ occupation }}
      </v-col>
    </v-row>
    <v-row no-gutters class="ProCard--Section mt-3" align="center">
      <v-col cols="12">
        <v-row no-gutters align="center">
          <v-col cols="2">
            <v-row no-gutters justify="center">
              <v-icon color="primary">mdi-briefcase-outline</v-icon>
            </v-row>
          </v-col>
          <v-col cols="10" class="ProCard--Text">
            <div>
              {{ denomination ? denomination : `${firstName} ${lastName}` }}
            </div>
            <div>
              {{ website }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="ProCard--Section" align="center">
      <v-col cols="12">
        <v-row no-gutters align="center">
          <v-col cols="2">
            <v-row no-gutters justify="center">
              <v-icon color="primary">mdi-phone-outline</v-icon>
            </v-row>
          </v-col>
          <v-col cols="10" class="ProCard--Text">
            {{ phoneNumber ? phoneNumber : "N/A" }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="ProCard--Section" align="center">
      <v-col cols="12">
        <v-row no-gutters align="center">
          <v-col cols="2">
            <v-row no-gutters justify="center">
              <v-icon color="primary">mdi-email-outline</v-icon>
            </v-row>
          </v-col>
          <v-col cols="10" class="ProCard--Text">
            {{ email ? email : "N/A" }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProCard",
  props: {
    photo: {
      type: String,
      required: false,
    },
    occupation: {
      type: String,
      required: false,
    },
    denomination: {
      type: String,
      required: false,
    },
    website: {
      type: String,
      required: false,
    },
    phoneNumber: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
    firstName: {
      type: String,
      required: false,
    },
    lastName: {
      type: String,
      required: false,
    },
    hasNotSubscribed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      reveal: false,
    };
  },
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style>
@import "../styles/ProfessionalProfile/pro-card.css";
</style>