<template>
  <div id="professional-profile">
    <v-container fluid v-if="detailedCard.user.firstName">
      <v-row>
        <v-col cols="1">
          <v-btn icon color="#37A3C6">
            <v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-0">
              <h1>
                {{
                  `${detailedCard.user.firstName} ${detailedCard.user.lastName}`
                }}
              </h1>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-icon color="#37A3C6">mdi-briefcase-outline</v-icon>
              <span class="subtitle-1"> {{ occupation }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <ProfessionalProfileStatsTile
            title="Abonnement"
            :subTitle="subTitleFromMonths('sub')"
            :value="value('sub')"
          />
        </v-col>
        <v-col cols="3">
          <ProfessionalProfileStatsTile
            title="Apparitions"
            :subTitle="subTitleFromMonths('appearance')"
            :value="nTimesAppeared.toString()"
          />
        </v-col>
        <v-col cols="3">
          <ProfessionalProfileStatsTile
            title="Apport partenariats"
            :subTitle="subTitleFromMonths('appearance')"
            :value="value('partnershipContribution')"
          />
        </v-col>
      </v-row>
      <v-row v-if="hasSubscription" class="pt-5">
        <v-col cols="12">
          <h2>Critères sélectionnés</h2>
        </v-col>
        <v-col class="ProfessionalProfile--TileCriteria ma-3">
          <v-row>
            <v-col cols="12" class="pb-0">
              <h4>Localisations</h4>
            </v-col>
            <v-col cols="12" class="ProfessionalProfile--TileTitle py-0">
              <h6>Par tribunal de commerce</h6>
            </v-col>
            <v-col cols="12">
              <span
                :key="loc"
                v-for="loc in commercialCourts"
                class="ProfessionalProfile--TileCriteriaListItem pb-1"
              >
                {{ loc | formatToOnlyFirstWordCapital }}
              </span>
              <div
                v-if="!commercialCourts.length"
                class="ProfessionalProfile--TileCriteriaListItem"
              >
                Aucun
              </div>
            </v-col>
            <v-col cols="12" class="ProfessionalProfile--TileTitle py-0">
              <h6>Par tribunal judiciaire</h6>
            </v-col>
            <v-col cols="12">
              <span
                :key="loc"
                v-for="loc in legalCourts"
                class="ProfessionalProfile--TileCriteriaListItem pb-1"
              >
                {{ loc | formatToOnlyFirstWordCapital }}
              </span>
              <div
                v-if="!legalCourts.length"
                class="ProfessionalProfile--TileCriteriaListItem"
              >
                Aucun
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ProfessionalProfile--TileCriteria ma-3">
          <v-row>
            <v-col cols="12" class="pb-0">
              <h4>Chiffre d'affaire</h4>
            </v-col>
            <v-col cols="12">
              <span
                :key="price"
                v-for="price in caPrices"
                class="ProfessionalProfile--TileCriteriaListItem pb-1"
              >
                {{ price }}
              </span>
              <div
                v-if="!caPrices.length"
                class="ProfessionalProfile--TileCriteriaListItem"
              >
                Aucun
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ProfessionalProfile--TileCriteria ma-3">
          <v-row>
            <v-col cols="12" class="pb-0">
              <h4>Procédures</h4>
            </v-col>
            <v-col cols="12">
              <span
                :key="price"
                v-for="price in procedurePrices"
                class="ProfessionalProfile--TileCriteriaListItem pb-1"
              >
                {{ price }}
              </span>
              <div
                v-if="!procedurePrices.length"
                class="ProfessionalProfile--TileCriteriaListItem"
              >
                Aucun
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <h2>Fiche professionel</h2>
        </v-col>
        <v-col cols="3">
          <ProCard
            :occupation="occupation"
            :email="detailedCard.user.email"
            :denomination="detailedCard.user.enterprise.denomination"
            :phoneNumber="detailedCard.user.phoneNumber"
            :photo="getPath(detailedCard.user)"
            :website="detailedCard.user.enterprise.website"
            :firstName="detailedCard.user.firstName"
            :lastName="detailedCard.user.lastName"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-skeleton-loader
      v-else
      max-width="300"
      type="article"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import Occupation from "../enums/Occupation.enum";
import ProfessionalProfileStatsTile from "@/components/ProfessionalProfileStatsTile";
import ProCard from "@/components/ProCard";
import commercialCourtsData from "@/utils/lst-greffes.json";
import legalCourtsData from "@/utils/lst-legal-courts.json";
import ProcedurePrices from "@/enums/ProcedurePrices.enum";
import TurnoverRangePrices from "@/enums/TurnoverRangePrices.enum";
import { find, some } from "lodash/core";

export default {
  name: "ProfessionalProfile",
  components: {
    ProfessionalProfileStatsTile,
    ProCard,
  },
  data() {
    return {
      detailedCard: {
        user: {},
      },
      legalCourtsData,
      commercialCourtsData,
      procedurePricesEnum: ProcedurePrices,
      turnoverRangePricesEnum: TurnoverRangePrices,
    };
  },
  async created() {
    this.detailedCard = await this.$store.dispatch(
      "professionals/fetchOne",
      this.$route.params.id
    );
  },
  computed: {
    hasSubscription() {
      return !!this.detailedCard.subscriptionInfos?.subscription
    },
    occupation() {
      switch (this.detailedCard.user.enterprise?.occupation) {
        case Occupation.MANDATAIRE_JUDICIAIRE:
          return "Mandataire judiciaire";
        case Occupation.AVOCAT:
          return "Avocat";
        case Occupation.ADMINISTRATEUR_JUDICIARE:
          return "Administrateur judiciaire";
        case Occupation.EXPERT_COMPTABLE:
          return "Expert comptable";
        default:
          return "Profession non renseignée";
      }
    },
    nTimesAppeared() {
      return this.detailedCard.appearance
        ? this.detailedCard.appearance.showedNTimes
        : 0;
    },
    commercialCourts() {
      return (
        this.detailedCard.subscriptionInfos?.subscription?.metadata.commercialCourtsStringified?.split(
          "¤"
        ) ?? []
      ).map(
        (key) =>
          find(commercialCourtsData, (court) => court.code_greffe === key)
            .greffe
      );
    },
    legalCourts() {
      return (
        this.detailedCard.subscriptionInfos?.subscription?.metadata.legalCourtsStringified?.split(
          "¤"
        ) ?? []
      ).map(
        (key) => find(legalCourtsData, (court) => court.tj_code === key).tj
      );
    },
    prices() {
      return this.detailedCard.subscriptionInfos?.subscription?.items.data.map(
        (product) => {
          return { id: product.price.id, name: product.price.nickname };
        }
      );
    },
    procedurePrices() {
      return this.prices
        ?.filter((price) => {
          return some(
            Object.values(this.procedurePricesEnum),
            (p) => p === price.id
          );
        })
        .map((price) => price.name);
    },
    caPrices() {
      return this.prices
        ?.filter((price) => {
          return some(
            Object.values(this.turnoverRangePricesEnum),
            (p) => p === price.id
          );
        })
        .map((price) => price.name);
    },
  },
  methods: {
    subTitleFromMonths(type) {
      let nb = 0;
      if (type === "sub") {
        nb = this.detailedCard.subscriptionInfos?.fromNMonths ?? 0;
      } else if (type === "partnershipContribution") {
        nb = this.detailedCard.partnershipContribution?.fromNMonths ?? 0;
      } else if (type === "appearance") {
        nb = this.detailedCard.appearance?.fromNMonths ?? 0;
      }

      return `Depuis ${nb} mois`;
    },
    value(type) {
      let value = 0;
      if (type === "sub") {
        value = this.detailedCard.subscriptionInfos?.price ?? "";
      } else if (type === "partnershipContribution") {
        value = this.detailedCard.partnershipContribution?.price ?? "";
      }

      return value + " €";
    },
    getPath(pro) {
      return pro.enterprise.logo ? pro.enterprise.logo.path : null;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/ProfessionalProfile/professional-profile.less";
</style>
